<template>
  <v-card
    v-ripple
    class="account-card dark rounded-lg elevation-10"
    @click="fund_accounts_dialog = true"
    style="height: 100%"
  >
    <div class="d-flex flex-column justify-space-between bs-h-100 pa-4 pa-sm-6">
      <v-avatar tile size="55" class="light rounded-lg mb-4 mb-sm-6">
        <img
          style="width: 32px; height: 32px"
          class="d-inline-block"
          src="https://img.icons8.com/3d-fluency/94/coin-wallet.png"
          alt="receive-cash"
        />
      </v-avatar>

      <div class="d-block">
        <div class="font-weight-bold">Withdrawal Accounts</div>
        <div class="grey--text mt-2">Manage your withdrawal accounts</div>
      </div>
    </div>

    <AddFundDialog
      :fund_accounts_dialog="fund_accounts_dialog"
      @close_dialog="fund_accounts_dialog = false"
    />
  </v-card>
</template>

<script>
import AddFundDialog from "@/components/app/account/funds/AddFundDialog.vue";

export default {
  data: () => ({
    fund_accounts_dialog: false,
    loading: false,
    headers: [
      { text: "Type", value: "fund", class: "text-uppercase" },
      { text: "Account Number", value: "account_number", class: "text-uppercase" },
      { text: "Status", value: "is_verified", class: "text-uppercase" },
      {
        text: "Action",
        value: "action",
        class: "text-uppercase",
        align: "right",
        sortable: false,
      },
    ],
  }),
  components: {
    AddFundDialog,
  },
};
</script>
