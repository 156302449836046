<template>
  <div class="deactivate-account">
    <v-menu offset-y left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon small v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item link @click.prevent="dialog = true">
          <v-list-item-icon>
            <v-icon>mdi-account-lock-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Deactivate Account</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog v-model="dialog" :max-width="alert_message != '' ? 500 : 400" persistent>
      <v-card class="bs-shadow-none">
        <div class="pa-4 pa-sm-6">
          <h3 class="font-weight-bold mb-3">Please Confirm</h3>
          <v-alert v-if="alert_message" class="mb-0" :type="alert_type" text role="alert">
            Your account has been successfully deactivated.
          </v-alert>
          <div v-else>
            <div class="grey--text">
              You're about to deactivate your account. Please enter your reason and
              password to proceed.
            </div>
            <div class="mt-4 mt-sm-6">
              <v-form ref="form" :disabled="loading" @submit.prevent="deactivateAccount">
                <v-text-field
                  v-model="reason"
                  counter="50"
                  :rules="[rules.required, rules.maximum(50)]"
                  hide-details="auto"
                  class="mb-4"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  autofocus
                  ref="password"
                  v-model="password"
                  :rules="[rules.required]"
                  :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show_password ? 'text' : 'password'"
                  :error-messages="validation_error.password"
                  label="Password"
                  outlined
                  dense
                  block
                  @click:append="show_password = !show_password"
                  hide-details="auto"
                ></v-text-field>
                <div class="d-flex justify-end bs-gap-2 mt-4 mt-sm-6">
                  <v-btn text @click="closeDialog" :disabled="loading">Cancel</v-btn>
                  <v-btn :loading="loading" depressed color="primary" type="submit"
                    >Deactivate</v-btn
                  >
                </div>
              </v-form>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import response_post from "@/helpers/api_request_post";
import form_rules from "@/helpers/formRules";

export default {
  data: () => ({
    rules: form_rules,
    dialog: false,
    password: "",
    show_password: false,
    loading: false,
    alert_type: "",
    alert_message: "",
    validation_error: [],
    reason: "",
  }),
  methods: {
    async deactivateAccount() {
      this.alert_type = "";
      this.alert_message = "";

      if (this.$refs.form.validate()) {
        this.loading = true;

        const form_data = new FormData();
        form_data.append("reason", this.reason);
        form_data.append("password", this.password);

        const res = await response_post(form_data, "account/deactivate");

        switch (res.status) {
          case 200:
            {
              this.alert_type = res.data.status;
              this.alert_message = res.data.message;
              this.logoutUser();
            }
            break;
          case 422:
            {
              this.alert_type = res.data.status;
              this.validation_error = res.data.messages;
            }
            break;
          case 500:
            {
              this.alert_type = res.data.status;
              this.alert_message = res.data.message;
            }
            break;
        }

        this.loading = false;
      }
    },
    logoutUser() {
      setTimeout(() => {
        this.dialog = false;

        this.$store.commit("resetStates", "", { root: true });
        this.$store.commit("setUserData", null, { root: true });
        this.$store.commit("setUserToken", {}, { root: true });
        localStorage.removeItem("userToken");
        this.$router.push({ name: "Home" });
      }, 5000);
    },
    closeDialog() {
      this.dialog = false;
      this.$refs.form.reset();
    },
  },
};
</script>
