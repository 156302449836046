<template>
  <div>
    <div class="font-weight-bold mb-4 mb-sm-6">Selfie and Valid ID</div>
    <v-form ref="form" v-model="valid" @submit.prevent="handleSubmit">
      <div class="mb-4 mb-sm-6">
        <div class="bs-row bs-g-3">
          <div class="bs-col-sm-6">
            <label
              for="valid_id"
              v-ripple
              class="pictures light d-flex align-center justify-center text-center rounded-lg overflow-hidden ma-0"
            >
              <div v-if="valid_id_url" style="width: 100%; position: relative">
                <img
                  :src="valid_id_url"
                  alt="..."
                  style="width: 100%; height: auto"
                  class="d-block"
                />
                <div class="pic-name-wrapper text-left white--text pa-4">
                  Valid ID
                </div>
              </div>

              <div v-else class="d-flex flex-column bs-gap-1">
                <v-icon>mdi-image</v-icon>
                <span class="grey--text">
                  <span class="d-none d-sm-inline">Click to browse</span>
                  <span class="d-inline d-sm-none">Tap to browse</span>
                  <br />
                  Valid ID
                </span>
                <span class="error--text shake" v-show="!isValidID"
                  >Please upload an image</span
                >
              </div>
            </label>
            <v-file-input
              id="valid_id"
              ref="valid_id"
              v-model="valid_id"
              :rules="[rules.required]"
              accept=".png,.jpg,.jpeg"
              prepend-icon=""
              append-icon="mdi-camera"
              show-size
              @change="validIDOnFileChange"
              @click:clear="validIDClearFile"
              label="Valid ID"
              placeholder="Browse"
              dense
              outlined
              hint="Must be a valid id"
              persistent-hint
              validate-on-blur
              hide-details="auto"
              class="d-none"
            ></v-file-input>
          </div>
          <div class="bs-col-sm-6">
            <label
              for="selfie"
              v-ripple
              class="pictures light d-flex align-center justify-center text-center rounded-lg overflow-hidden ma-0"
            >
              <div v-if="selfie_url" style="width: 100%; position: relative">
                <img
                  :src="selfie_url"
                  alt="..."
                  style="width: 100%; height: auto"
                  class="d-block"
                />
                <div class="pic-name-wrapper text-left white--text pa-4">
                  Selfie
                </div>
              </div>
              <div v-else class="d-flex flex-column bs-gap-1">
                <v-icon>mdi-image</v-icon>
                <span class="grey--text">
                  <span class="d-none d-sm-inline">Click to browse</span>
                  <span class="d-inline d-sm-none">Tap to browse</span>
                  <br />
                  Selfie
                </span>
                <span class="error--text shake" v-show="!isValidSelfie"
                  >Please upload an image</span
                >
              </div>
            </label>
            <v-file-input
              id="selfie"
              ref="selfie"
              v-model="selfie"
              :rules="[rules.required]"
              accept=".png,.jpg,.jpeg"
              prepend-icon=""
              append-icon="mdi-camera"
              show-size
              @change="selfieOnFileChange"
              @click:clear="selfieClearFile"
              label="Selfie"
              placeholder="Browse"
              dense
              outlined
              validate-on-blur
              hide-details="auto"
              class="d-none"
            ></v-file-input>
          </div>
        </div>
      </div>

      <div class="d-flex justify-end">
        <v-btn
          block
          depressed
          color="primary"
          type="submit"
          :loading="updatePicturesLoading"
        >
          Save
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formRules from "@/helpers/formRules";

export default {
  data: () => ({
    valid: false,
    rules: formRules,
    valid_id: null,
    valid_id_url: null,
    selfie: null,
    selfie_url: null,
    isValidID: true,
    isValidSelfie: true,
  }),
  computed: {
    ...mapState("account", ["updatePicturesHasError", "updatePicturesLoading"]),
    ...mapState(["appData", "accountStatus"]),
  },
  methods: {
    ...mapActions("account", ["doUpdatePictures"]),
    validIDOnFileChange(e) {
      if (e) {
        this.valid_id_url = URL.createObjectURL(e);
      }
    },
    validIDClearFile() {
      this.valid_id_url = null;
    },
    selfieOnFileChange(e) {
      if (e) {
        this.selfie_url = URL.createObjectURL(e);
      }
    },
    selfieClearFile() {
      this.selfie_url = null;
    },
    async handleSubmit() {
      if (!this.$refs.form.validate()) {
        this.isValidID = this.valid_id_url !== null;
        this.isValidSelfie = this.selfie_url !== null;
        return;
      }

      if (!this.updatePicturesLoading) {
        const formData = new FormData();
        formData.append("valid_id", this.valid_id);
        formData.append("selfie", this.selfie);

        const res = await this.doUpdatePictures(formData);

        if (!this.updatePicturesHasError) {
          this.appData.account_details.valid_id = res.valid_id;
          this.appData.account_details.selfie = res.selfie;

          if (res.completed_verification) {
            this.$store.commit("setAccountStatus", "Files Submitted");
            this.appData.account_details.account_verified = "Files Submitted";
            this.$emit("verifySubmitted");
          }

          this.$emit("picturesCompleted");
        }
      }
    },
  },
};
</script>

<style scoped>
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.pictures {
  width: 100%;
  height: 200px;
  position: relative;
}

@media (max-width: 768px) {
  .pictures {
    height: 150px;
  }
}
.pic-name-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.75) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
</style>
