<template>
  <div class="main-content pa-4 pa-sm-6 pa-md-8">
    <v-card class="elevation-10 mb-sm-4">
      <div class="pa-4 pa-sm-6">
        <DeactivateAccount class="float-end" />
        <div class="d-flex flex-column">
          <h3 class="font-weight-bold">My Account</h3>
          <div class="grey--text mt-2 mt-2">Manage your account</div>
        </div>
        <div v-if="accountStatus == 'Pending'">
          <v-alert type="info" text class="mb-0 mt-4 mt-sm-6">
            Please
            {{
              !isPersonalInfoCompleted
                ? " complete your personal information,"
                : ""
            }}
            {{
              !isPersonalInfoCompleted && !isPicturesCompleted
                ? " "
                : " wait for a moment, your account is being verified. It may take 5 to 30 minutes"
            }}
            {{ !isPicturesCompleted ? " upload required photos" : "" }}
            to verify your account.
          </v-alert>
          <v-alert v-model="successAlert" type="success" text class="mb-0 mt-4">
            {{ successAlertText }}
          </v-alert>
        </div>
        <v-alert
          type="info"
          text
          class="mb-0 mt-4 mt-sm-6"
          v-if="accountStatus == 'Files Submitted'"
        >
          Due to the high influx of registrants, verification may take a while.
        </v-alert>
      </div>
    </v-card>

    <div class="mb-sm-4" v-if="$vuetify.breakpoint.mdAndUp">
      <div class="py-4 py-sm-0">
        <div class="bs-row bs-g-3">
          <div class="bs-col-12 bs-col-md-4">
            <v-card
              v-ripple
              class="account-card dark rounded-lg elevation-10"
              style="height: 100%"
              @click="kyc_dialog = true"
            >
              <div
                class="d-flex flex-column justify-space-between bs-h-100 pa-4 pa-sm-6"
              >
                <v-avatar tile size="55" class="light rounded-lg mb-6">
                  <img
                    style="width: 32px; height: 32px"
                    src="https://img.icons8.com/3d-fluency/94/person-male--v3.png"
                    alt="person-male--v3"
                  />
                </v-avatar>

                <div class="d-block">
                  <div class="font-weight-bold">Personal Information</div>
                  <div class="grey--text mt-2">
                    Update or view your personal information
                  </div>
                </div>
              </div>
            </v-card>
          </div>
          <div class="bs-col-12 bs-col-md-4" v-if="!isSalesRep">
            <Funds style="height: 100%" />
          </div>
          <div class="bs-col-12 bs-col-md-4">
            <v-card
              v-ripple
              class="account-card dark rounded-lg elevation-10"
              @click="login_password_dialog = true"
              style="height: 100%"
            >
              <div
                class="d-flex flex-column justify-space-between bs-h-100 pa-4 pa-sm-6"
              >
                <v-avatar tile size="55" class="light rounded-lg mb-6">
                  <img
                    style="width: 32px; height: 32px"
                    class="d-inline-block"
                    src="https://img.icons8.com/3d-fluency/94/password.png"
                    alt="receive-cash"
                  />
                </v-avatar>
                <div class="d-block">
                  <div class="font-weight-bold">Account Password</div>
                  <div class="grey--text mt-2">
                    Change your account password
                  </div>
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </div>
    <div class="sm mb-sm-4" v-else>
      <div class="pa-4 pa-sm-0">
        <v-card
          v-ripple
          class="account-card dark rounded-lg elevation-10"
          style="height: 100%"
          @click="kyc_dialog = true"
        >
          <div
            class="d-flex flex-column justify-space-between bs-h-100 pa-4 pa-sm-6"
          >
            <v-avatar tile size="55" class="light rounded-lg mb-4 mb-sm-6">
              <img
                style="width: 32px; height: 32px"
                class="d-inline-block"
                src="https://img.icons8.com/3d-fluency/94/receive-cash.png"
                alt="receive-cash"
              />
            </v-avatar>

            <div class="d-block">
              <div class="font-weight-bold">Personal Information</div>
              <div class="grey--text mt-2">
                Update or view your personal information
              </div>
            </div>
          </div>
        </v-card>

        <v-divider></v-divider>

        <Funds style="height: 100%" />

        <v-divider></v-divider>

        <v-card
          v-ripple
          class="account-card dark rounded-lg elevation-10"
          @click="login_password_dialog = true"
          style="height: 100%"
        >
          <div
            class="d-flex flex-column justify-space-between bs-h-100 pa-4 pa-sm-6"
          >
            <v-avatar tile size="55" class="light rounded-lg mb-4 mb-sm-6">
              <img
                style="width: 32px; height: 32px"
                class="d-inline-block"
                src="https://img.icons8.com/3d-fluency/94/password.png"
                alt="receive-cash"
              />
            </v-avatar>

            <div class="d-block">
              <div class="font-weight-bold">Account Password</div>
              <div class="grey--text mt-2">Change your account password</div>
            </div>
          </div>
        </v-card>
      </div>
    </div>

    <Transactions v-if="accountStatus" :account_status="accountStatus" />

    <v-dialog v-model="kyc_dialog" max-width="600" scrollable>
      <v-card class="bs-shadow-none">
        <div class="pa-4 pa-sm-6" style="overflow-y: auto">
          <!-- <a href="#" @click.prevent="kyc_dialog = false" class="float-right">Close</a> -->
          <PersonalInfo
            class="mb-4 mb-sm-6"
            @personalInfoCompleted="personalInfoCompleted"
            @verifySubmitted="verifySubmitted"
          />
          <Pictures
            v-if="isNoPicturesSubmitted"
            @picturesCompleted="picturesCompleted"
            @verifySubmitted="verifySubmitted"
          />
          <FilesSubmitted v-else />
          <div class="d-flex justify-end mt-4 mt-sm-6">
            <v-btn block class="light" depressed @click="kyc_dialog = false"
              >Close</v-btn
            >
          </div>
        </div>
      </v-card>
    </v-dialog>

    <ChangePassword
      :login_password_dialog="login_password_dialog"
      @close_dialog="login_password_dialog = false"
    />

    <v-dialog v-if="dialog" v-model="dialog" width="300" persistent>
      <v-card class="bs-shadow-none">
        <div class="pa-4 pa-sm-6">
          <div class="font-weight-bold mb-2">{{ dialogTitle }}</div>
          <div class="grey--text mt-2 mb-4 mb-sm-6">
            {{ dialogText }}
          </div>
          <div class="d-flex justify-end">
            <v-btn depressed color="primary" @click="continueOk">Ok</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import FilesSubmitted from "@/components/app/account/FilesSubmitted";
import PersonalInfo from "@/components/app/account/PersonalInfo";
import Pictures from "@/components/app/account/Pictures";
import ChangePassword from "@/components/app/account/ChangePassword";
import Funds from "@/components/app/account/Funds";
import DeactivateAccount from "@/components/app/account/DeactivateAccount";
import Transactions from "@/components/app/account/Transactions";

export default {
  components: {
    FilesSubmitted,
    PersonalInfo,
    Pictures,
    ChangePassword,
    Funds,
    DeactivateAccount,
    Transactions,
  },
  data: () => ({
    dialog: false,
    kyc_dialog: false,
    fund_accounts_dialog: false,
    login_password_dialog: false,
    successAlert: false,
    successAlertText: "",
    dialogTitle: "Success",
    dialogText: "Your account verification is now under processing.",
    sales_rep: false,
  }),
  mounted() {},
  computed: {
    ...mapState(["appData", "accountStatus", "pagcorMode"]),
    ...mapGetters([
      "isPersonalInfoCompleted",
      "isPicturesCompleted",
      "isNoPicturesSubmitted",
    ]),
    isSalesRep() {
      if (
        this.appData &&
        this.appData.account_details.investor_sub_agent_player_id &&
        this.appData.account_details.investor_sub_type_id == 1
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    verifySubmitted() {
      if (this.accountStatus == "Verified") {
        this.dialogTitle = "Success";
        this.dialogText = "Your account has been successfully updated.";
      }

      this.dialog = true;
    },
    continueOk() {
      location.reload();
    },
    personalInfoCompleted() {
      this.successAlert = true;
      this.successAlertText =
        "Your personal information was successfully updated.";
      this.kyc_dialog = false;

      setTimeout(() => {
        this.successAlert = false;
        this.successAlertText = "";
      }, 5000);
    },
    picturesCompleted() {
      this.successAlert = true;
      this.successAlertText =
        "Your valid id and selfie was successfully updated.";
      this.kyc_dialog = false;

      setTimeout(() => {
        this.successAlert = false;
        this.successAlertText = "";
      }, 5000);
    },
  },
};
</script>

<style scoped>
.account-card {
  cursor: pointer;
}
@media (max-width: 576px) {
  .sm .account-card {
    border-radius: 0 !important;
  }

  .sm .account-card:first-of-type {
    border-bottom: none !important;
    border-top-right-radius: 0.5rem !important;
    border-top-left-radius: 0.5rem !important;
  }

  .sm .account-card:last-of-type {
    border-top: none !important;
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
}
</style>
