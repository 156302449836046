<template>
  <v-dialog v-model="dialog" max-width="400" scrollable>
    <v-card class="bs-shadow-none">
      <div class="pa-4 pa-sm-6">
        <v-alert v-model="successAlert" type="success" text class="mb-4 mb-sm-6">
          Successfully changed password! You will be logged out in 5 seconds.
        </v-alert>
        <div class="font-weight-bold mb-4 mb-sm-6">Account Password</div>
        <v-form ref="form" v-model="valid" @submit.prevent="handleSubmit">
          <div class="bs-row bs-g-3 mb-4">
            <div class="bs-col-12">
              <v-text-field
                ref="current_password"
                v-model="current_password"
                :rules="[rules.required, wrongPassword]"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                label="Current Password"
                outlined
                dense
                hide-details="auto"
                @click:append="show1 = !show1"
              ></v-text-field>
            </div>
            <div class="bs-col-12">
              <v-text-field
                ref="new_password"
                v-model="new_password"
                :rules="[rules.required, rules.minimum(6), newPassDifferent]"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                label="New Password"
                outlined
                dense
                hide-details="auto"
                @click:append="show2 = !show2"
              ></v-text-field>
            </div>
            <div class="bs-col-12">
              <v-text-field
                v-model="confirm_password"
                :rules="[rules.required, passwordConfirmationRule]"
                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show3 ? 'text' : 'password'"
                label="Confirm Password"
                outlined
                dense
                hide-details="auto"
                @click:append="show3 = !show3"
              ></v-text-field>
            </div>
          </div>
          <div class="d-flex justify-end bs-gap-2 mt-4 mt-sm-6">
            <v-btn @click="dialog = false" text>Close</v-btn>
            <v-btn
              depressed
              color="primary"
              :disabled="!valid"
              type="submit"
              :loading="changePasswordLoading"
            >
              Save
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formRules from "@/helpers/formRules";

export default {
  data: () => ({
    dialog: false,
    valid: false,
    rules: formRules,
    show1: false,
    show2: false,
    show3: false,
    current_password: "",
    new_password: "",
    confirm_password: "",
    wrongPasswordText: "",
    newPassDifferentText: "",
    successAlert: false,
  }),
  props: {
    login_password_dialog: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState("account", ["changePasswordError", "changePasswordLoading"]),
    ...mapState(["appData"]),
    ...mapState("logout", ["logoutLoading"]),
    passwordConfirmationRule() {
      return () => this.new_password === this.confirm_password || "Password must match";
    },
  },
  methods: {
    ...mapActions("account", ["doChangePassword"]),
    ...mapActions("logout", ["doLogout"]),
    wrongPassword(val) {
      if (val == this.wrongPasswordText) {
        return "Wrong password.";
      } else {
        return true;
      }
    },
    newPassDifferent(val) {
      if (val == this.newPassDifferentText) {
        return "The new password must not equal the old password.";
      } else {
        return true;
      }
    },
    async logout() {
      if (!this.logoutLoading) {
        await this.doLogout();

        this.$router.push({ name: "Home" });
      }
    },
    async handleSubmit() {
      if (this.$refs.form.validate()) {
        if (!this.changePasswordLoading) {
          const formData = new FormData();
          formData.append("current_password", this.current_password);
          formData.append("new_password", this.new_password);
          formData.append("confirm_password", this.confirm_password);

          await this.doChangePassword(formData);

          if (!this.changePasswordError) {
            this.$refs.form.reset();

            this.successAlert = true;
            setTimeout(() => {
              this.successAlert = false;
              this.logout();
            }, 5000);
          } else {
            if (this.changePasswordError == "Wrong password.") {
              this.wrongPasswordText = this.current_password;
              this.$refs.current_password.focus();
            }

            if (
              this.changePasswordError ==
              "The new password must not equal the old password."
            ) {
              this.newPassDifferentText = this.new_password;
              this.$refs.new_password.focus();
            }

            this.$refs.form.validate();
          }
        }
      }
    },
  },
  watch: {
    login_password_dialog(val) {
      if (val) {
        this.dialog = val;
      }
    },
    dialog(val) {
      if (!val) {
        this.$emit("close_dialog");
      }
    },
  },
};
</script>
