import store from '../store'
const url = process.env.VUE_APP_API_URL

const request_post = async (payload, end_point) => {
  try {
    let response_data = {}
    let response_status = 0
    const user_token = store.state.userToken

    const response = await fetch(`${url}${end_point}` , {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${user_token}`,
      },
      body: payload
    });

    const res = await response.json()
    response_status = response.status
    response_data = {
      status: response_status,
      data: res
    } 
    
    return response_data
  } catch(error) {
    return error
  } 
}

export default request_post