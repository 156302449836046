<template>
  <v-dialog v-model="dialog" :max-width="stepper == 2 ? 400 : 700" scrollable>
    <v-card class="bs-shadow-none" :loading="fund_accounts_loading">
      <div style="overflow-y: auto">
        <div class="pa-4 pa-sm-6">
          <v-stepper v-model="stepper" class="bs-shadow-none">
            <v-stepper-items>
              <v-stepper-content step="1" class="bs-shadow-none pa-0">
                <div class="font-weight-bold mb-1 mb-sm-2">
                  Add Account
                </div>
                <div class="grey--text mb-4 mb-sm-6">Select an outlet</div>
                <div v-if="!banks_loading">
                  <v-btn-toggle class="d-block bs-bg-transparent outlet">
                    <v-slide-group show-arrows>
                      <v-slide-item v-for="item in banks" :key="item.id">
                        <div
                          class="d-flex justify-center align-center bs-overflow-hidden"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="
                                  setDialog(item.bank, item.id, item.type)
                                "
                                v-bind="attrs"
                                v-on="on"
                                text
                                class="light me-3"
                              >
                                <v-img
                                  :src="item.image"
                                  alt="League"
                                  width="32"
                                  height="32"
                                  class="rounded-circle"
                                >
                                </v-img>
                              </v-btn>
                            </template>
                            <span>{{ item.bank }}</span>
                          </v-tooltip>
                        </div>
                      </v-slide-item>
                    </v-slide-group>
                  </v-btn-toggle>
                </div>
                <div v-else>
                  <div class="d-flex bs-gap-2">
                    <div class="rounded-lg overflow-hidden">
                      <v-skeleton-loader
                        type="image"
                        width="64"
                        height="64"
                      ></v-skeleton-loader>
                    </div>
                  </div>
                </div>
              </v-stepper-content>

              <v-stepper-content step="2" class="bs-shadow-none pa-0">
                <div class="font-weight-bold mb-2">
                  {{ `Add Account — ${fund_title}` }}
                </div>
                <div class="grey--text mb-4 mb-sm-6">
                  Enter your account number.
                </div>
                <v-alert
                  v-if="response_message"
                  :type="response_type"
                  text
                  class="mb-4 mb-sm-6"
                >
                  {{ response_message }}
                </v-alert>
                <v-alert v-else class="mb-4 mb-sm-6" text type="info">
                  Additional fund account should be registered in your name.
                </v-alert>
                <v-form ref="form" @submit.prevent="validateOTP">
                  <div v-if="this.type == 'E-Wallet'">
                    <div style="position: relative">
                      <v-text-field
                        ref="mobile_number"
                        v-model="mobile_number"
                        :rules="[rules.required, rules.validMobileNumber]"
                        maxlength="10"
                        dense
                        outlined
                        type="text"
                        label="Account Number"
                        prefix="+63"
                        placeholder="Enter account number"
                        hide-details="auto"
                        :error-messages="validation_error_message.mobile_number"
                        class="mb-4"
                      />
                      <div
                        v-if="!isRegisteredNumberEqualToInputNumber"
                        class="d-flex align-center pe-3"
                        style="
                          margin-top: 9px;
                          position: absolute;
                          top: 0;
                          right: 0;
                          z-index: 2;
                        "
                      >
                        <a
                          href="#"
                          @click.prevent="sendOTP"
                          :style="
                            !hasValidMobileNumber || countdown
                              ? 'pointer-events: none; opacity: 0.5;'
                              : undefined
                          "
                        >
                          <v-progress-circular
                            v-if="request_otp_loading"
                            indeterminate
                            :size="14"
                            :width="2"
                            color="primary"
                          ></v-progress-circular>
                          <span v-else>
                            Send OTP
                            <span v-if="countdown && countdown != 0">
                              <span v-if="loading">Sending</span>
                              <span v-else> {{ ` (${countdown})` }}</span>
                            </span>
                          </span>
                        </a>
                      </div>
                    </div>
                    <v-text-field
                      v-if="!isRegisteredNumberEqualToInputNumber"
                      ref="otp"
                      v-model="otp"
                      :rules="[rules.required, rules.minimum(6), rules.numeric]"
                      label="OTP"
                      placeholder="XXXXXX"
                      outlined
                      dense
                      maxlength="6"
                      :error-messages="validation_error_message['otp']"
                      hide-details="auto"
                    >
                    </v-text-field>
                  </div>
                  <div v-else>
                    <v-text-field
                      label="Cardholder Name"
                      dense
                      outlined
                      hide-details="auto"
                      class="mb-4"
                    >
                    </v-text-field>
                    <!-- <v-autocomplete
                label="Bank"
                outlined
                dense
                hide-details="auto"
                class="mb-4"
              ></v-autocomplete> -->
                    <v-text-field
                      label="Account Number"
                      dense
                      outlined
                      hide-details="auto"
                    >
                    </v-text-field>
                  </div>
                  <div class="d-flex justify-end bs-gap-2 mt-4 mt-sm-6">
                    <v-btn text @click="resetForm()">Back</v-btn>
                    <v-btn
                      :disabled="
                        !isRegisteredNumberEqualToInputNumber &&
                        (!hasValidMobileNumber || !otp || otp.length != 6)
                      "
                      :loading="save_fund_account_loading"
                      depressed
                      color="primary"
                      type="submit"
                      >Submit</v-btn
                    >
                  </div>
                </v-form>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </div>
        <v-divider></v-divider>
        <div class="pa-4 pa-sm-6">
          <div class="font-weight-bold mb-1 mb-sm-2">
            <span v-if="fund_accounts && fund_accounts.length < 1"
              >Account</span
            >
            <span v-else>Accounts</span>
          </div>
          <div class="grey--text mb-4 mb-sm-6">Withdrawal account list</div>
          <div v-if="fund_accounts && fund_accounts != 0">
            <div class="bs-border rounded-lg overflow-hidden mt-4 mt-sm-6">
              <!-- <v-data-table
                :hide-default-footer="false"
                loading-text="Loading, please wait"
                :headers="headers"
                :items="fund_accounts"
                :items-per-page="item_per_page_value"
                :loading="fund_accounts_loading"
                :sort-by.sync="sort_by"
                :sort-desc.sync="sort_desc"
                :page.sync="page"
                @page-count="page_count = $event"
              >
                <template v-slot:item.bank="{ item }">
                  <div class="d-flex align-center bs-gap-3">
                    {{ item.bank }}
                    <span class="primary--text" v-if="item.is_default == 'Yes'">
                      Default
                    </span>
                  </div>
                </template>

                <template v-slot:item.account_number="{ item }">
                  {{ item.account_number }}
                </template>

                <template v-slot:item.is_verified="{ item }">
                  <span :class="statusColor(item.is_verified)">
                    {{ item.is_verified }}
                  </span>
                </template>

                <template v-slot:item.action="{ item }">
                  <a
                    href="#"
                    @click.prevent="changeDefaultWithdrawalAccount(item.id)"
                    v-if="item.is_default == 'No' && item.is_verified == 'Verified'"
                  >
                    Set as default
                  </a>
                  <span v-else>-</span>
                </template>
              </v-data-table> -->
              <!-- <div v-if="fund_accounts && fund_accounts.length != 0">
                <v-divider></v-divider>

                <div
                  class="d-flex align-center justify-space-between bs-gap-3 pa-4 pa-sm-6"
                >
                  <div class="d-flex align-center bs-gap-3">
                    <v-select
                      :value="item_per_page_value"
                      :items="item_per_page"
                      outlined
                      dense
                      label="Items"
                      :hide-details="true"
                      style="width: 100px"
                      @change="item_per_page_value = parseInt($event, 10)"
                    ></v-select>
                    <div>
                      Page
                      {{
                        page
                          ? page
                          : "0" + "-" + item_per_page_value
                          ? item_per_page_value
                          : "0"
                      }}
                      of {{ page_count ? page_count : "0" }}
                    </div>
                  </div>
                  <v-pagination
                    v-model="page"
                    :length="page_count"
                    :total-visible="0"
                  ></v-pagination>
                </div>
              </div> -->

              <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-uppercase">Outlet</th>
                    <th class="text-uppercase">Account Number</th>
                    <th class="text-uppercase">Status</th>
                    <th class="text-end">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in fund_accounts" :key="i">
                    <td>
                      <div class="d-flex align-center bs-gap-3">
                        {{ item.bank }}
                        <span
                          class="primary--text"
                          v-if="item.is_default == 'Yes'"
                        >
                          Default
                        </span>
                      </div>
                    </td>
                    <td>{{ item.account_number }}</td>
                    <td :class="statusColor(item.is_verified)">
                      {{ item.is_verified }}
                    </td>
                    <td>
                      <div class="d-flex justify-end">
                        <v-menu offset-y left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              v-bind="attrs"
                              v-on="on"
                              :disabled="
                                item.is_verified != 'Verified' ||
                                item.is_default == 'Yes'
                                  ? true
                                  : false
                              "
                            >
                              <v-icon>mdi-dots-horizontal</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item
                              link
                              @click.prevent="
                                changeDefaultWithdrawalAccount(item.id)
                              "
                            >
                              <v-list-item-title
                                >Set as default</v-list-item-title
                              >
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </div>
          <v-alert
            role="alert"
            v-else
            type="info"
            text
            class="mb-0 pa-sm-6 mt-4 mt-sm-6"
          >
            You don't have a withdrawal account yet. Select an outlet above to
            start adding.
          </v-alert>
          <!-- </div>
          <div
            v-else
            class="d-flex justify-center align-center text-center rounded-lg bs-border bs-gap-3 pa-4 pa-sm-6 mt-4 mt-sm-6"
          >
            <v-progress-circular
              indeterminate
              :size="14"
              :width="2"
              color="primary"
            ></v-progress-circular>
            Loading Accounts ...
          </div> -->

          <div class="d-flex justify-end mt-4 mt-sm-6">
            <v-btn close class="light" depressed @click="dialog = false"
              >Close</v-btn
            >
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import form_rules from "@/helpers/formRules";
import responseGet from "@/helpers/api_request_get";
import responsePost from "@/helpers/api_request_post";

export default {
  data: () => ({
    rules: form_rules,
    mobile_number: "",
    otp: "",
    stepper: 1,
    fund_title: "",
    fund_id: 0,
    type: "",
    loading: false,
    request_otp_loading: false,
    validate_otp_loading: false,
    save_fund_account_loading: false,
    response: false,
    response_message: "",
    response_type: "",
    validation_error_message: [],
    countdown: 0,
    interval: 0,
    banks: [],
    banks_loading: false,
    fund_accounts: [],
    fund_accounts_loading: false,
    image_has_error: false,
    dialog: false,

    headers: [
      { text: "Outlet", value: "bank", class: "text-uppercase" },
      {
        text: "Account Number",
        value: "account_number",
        class: "text-uppercase",
      },
      { text: "Status", value: "is_verified", class: "text-uppercase" },
      {
        text: "Action",
        value: "action",
        sortable: false,
        class: "text-uppercase",
        align: "right",
      },
    ],

    page: 1,
    page_count: 0,
    item_per_page: [5, 10, 20, 40, 80],
    item_per_page_value: 10,
    sort_by: "bet_at",
    sort_desc: true,
  }),
  mounted() {
    this.getBanks();
    this.getFundAccount();
  },
  props: {
    fund_accounts_dialog: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState(["userData"]),
    hasValidMobileNumber() {
      return this.mobile_number &&
        this.rules.validMobileNumber(this.mobile_number) == true
        ? true
        : false;
    },
    isRegisteredNumberEqualToInputNumber() {
      if (this.userData) {
        if (this.mobile_number == this.userData.mobile_number) return true;
      }

      return false;
    },
  },
  methods: {
    setDialog(name, id, type) {
      this.fund_title = name;
      this.fund_id = id;
      this.type = type;
      this.stepper = 2;
    },
    async getBanks() {
      this.banks_loading = true;

      const form_data = new FormData();
      form_data.append("transaction_type", "for_withdrawal");

      const data = new URLSearchParams(form_data);
      const res = await responseGet(data, "bank_types");

      switch (res.status) {
        case 200:
          {
            this.banks = res.data.data;
          }
          break;
      }

      this.banks_loading = false;
    },
    async getFundAccount() {
      this.fund_accounts_loading = true;

      const form_data = new FormData();

      const statuses = ["Verified", "Pending"];
      statuses.forEach(function (status) {
        form_data.append("status[]", status);
      });

      const search_param = new URLSearchParams(form_data);
      const res = await responseGet(search_param, "bank_accounts");

      switch (res.status) {
        case 200: {
          this.fund_accounts = res.data.data;
        }
      }

      this.fund_accounts_loading = false;
    },
    async sendOTP() {
      this.response_message = "";
      this.validation_error_message = {};

      if (this.$refs.mobile_number.validate()) {
        this.request_otp_loading = true;

        const form_data = new FormData();
        form_data.append("mobile_number", this.mobile_number);
        form_data.append("request_id", "3");
        form_data.append("bank_id", this.fund_id);

        const res = await responsePost(form_data, "otp/request");

        switch (res.status) {
          case 200:
            {
              this.response_type = "success";
              this.response_message = res.data.message;

              this.$refs.otp.focus();
              this.startCountdown();

              setTimeout(() => {
                this.response_type = "";
                this.response_message = "";
              }, 5000);
            }
            break;
          case 422:
            {
              this.validation_error_message = res.data.messages;
            }
            break;
          case 429:
            {
              this.response_type = res.data.status;
              this.response_message = res.data.messages;
            }
            break;
        }

        this.request_otp_loading = false;
      }
    },
    async validateOTP() {
      if (this.isRegisteredNumberEqualToInputNumber) {
        this.saveFundAccount();
      } else {
        this.response_message = "";
        this.validation_error_message = {};

        if (this.$refs.form.validate()) {
          this.validate_otp_loading = true;

          const form_data = new FormData();
          form_data.append("mobile_number", this.mobile_number);
          form_data.append("otp", this.otp);
          form_data.append("bank_id", this.fund_id);

          const res = await responsePost(form_data, "otp/validate");

          switch (res.status) {
            case 200:
              {
                this.saveFundAccount();
              }
              break;
            case 422:
              {
                this.validation_error_message = res.data.messages;
              }
              break;
          }

          this.validate_otp_loading = false;
        }
      }
    },
    async saveFundAccount() {
      this.save_fund_account_loading = true;

      this.response_message = "";
      this.validation_error_message = {};

      const form_data = new FormData();
      form_data.append("mobile_number", this.mobile_number);
      form_data.append("bank_id", this.fund_id);

      const res = await responsePost(form_data, "bank_accounts/add");

      switch (res.status) {
        case 200:
          {
            if (res.data.status == "success") {
              // Reset the interval
              this.countdown = 0;
              clearInterval(this.interval);
              // Set the success message
              this.response_type = res.data.status;
              this.response_message = res.data.message;

              // Reset the form
              setTimeout(() => {
                this.resetForm();
              }, 5000);
              // Refresh the withdrawal account
              this.getFundAccount();
            }
          }
          break;
        case 422:
          {
            if (res.data.status == "error") {
              this.validation_error_message = res.data.messages;
            }
          }
          break;
      }

      this.save_fund_account_loading = false;
    },
    startCountdown() {
      this.countdown = 60;
      this.interval = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          clearInterval(this.interval);
        }
      }, 1000);
    },
    resetForm() {
      this.response_message = "";
      this.response_type = "";
      this.validation_error_message = [];
      this.stepper = 1;

      this.$refs.form.reset();
    },
    statusColor(value) {
      let color = "";

      switch (value) {
        case "Verified":
          {
            color = "green--text";
          }
          break;
        case "Pending":
          {
            color = "orange--text";
          }
          break;
        case "Rejected":
          {
            color = "red--text";
          }
          break;
        default:
          color = "";
      }

      return color;
    },
    async changeDefaultWithdrawalAccount(id) {
      const form_data = new FormData();
      form_data.append("id", id);

      const res = await responsePost(form_data, "bank_accounts/change_default");

      if (res.status == 200) {
        this.getFundAccount();
      }
    },
  },
  watch: {
    fund_accounts_dialog(val) {
      if (val) {
        this.dialog = val;
      }
    },
    dialog(val) {
      if (!val) {
        this.$emit("close_dialog");
      }
    },
    stepper(val) {
      if (val == 2) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.mobile_number.focus();
          }, 300);
        });
      }
    },
  },
};
</script>

<style scoped>
.outlet .v-btn {
  min-width: calc(32px + 32px) !important;
  height: calc(32px + 32px) !important;
}
</style>
