<template>
  <div>
    <div class="font-weight-bold mb-4 mb-sm-6">Selfie and Valid ID</div>
    <div class="bs-row bs-g-3">
      <div class="bs-col-6">
        <div
          class="pictures light rounded-lg overflow-hidden"
          :style="accountStatus != 'Verified' ? 'cursor: pointer' : undefined"
        >
          <div
            class="pic-name-wrapper d-flex justify-space-between bs-gap-3 text-left white--text pa-4"
          >
            Valid ID
            <a
              href="#"
              v-if="
                accountStatus != 'Verified' && appData.account_details.valid_id
              "
              @click.prevent="updateValidID()"
              class="white--text"
            >
              Change
            </a>
          </div>
          <div
            class="d-flex align-center justify-center"
            style="position: relative; width: 100%; height: 100%"
          >
            <img
              v-if="appData.account_details.valid_id"
              :src="
                appData.account_details.valid_id +
                '?datetime=' +
                datetimeValidID
              "
              alt="..."
              class="d-block"
              style="
                width: 100%;
                height: auto;
                position: absolute;
                top: auto;
                right: auto;
                bottom: auto;
                left: auto;
              "
            />
            <div
              v-else
              class="d-flex flex-column bs-gap-1"
              @click.prevent="updateValidID()"
            >
              <v-icon>mdi-image</v-icon>
              <span class="grey--text">
                <span class="d-none d-sm-inline">Click to browse</span>
                <span class="d-inline d-sm-none">Tap to browse</span>
                <br />
                Selfie
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="bs-col-6">
        <div
          class="pictures light rounded-lg overflow-hidden"
          :style="accountStatus != 'Verified' ? 'cursor: pointer' : undefined"
        >
          <div
            class="pic-name-wrapper d-flex justify-space-between bs-gap-3 text-left white--text pa-4"
          >
            Selfie
            <a
              href="#"
              v-if="
                accountStatus != 'Verified' && appData.account_details.selfie
              "
              @click.prevent="updateSelfie()"
              class="white--text"
              >Change</a
            >
          </div>
          <div
            class="d-flex align-center justify-center"
            style="position: relative; width: 100%; height: 100%"
          >
            <img
              v-if="appData.account_details.selfie"
              :src="
                appData.account_details.selfie + '?datetime=' + datetimeSelfie
              "
              alt="..."
              class="d-block"
              style="
                width: 100%;
                height: auto;
                position: absolute;
                position: absolute;
                top: auto;
                right: auto;
                bottom: auto;
                left: auto;
              "
            />
            <div
              v-else
              class="d-flex flex-column bs-gap-1"
              @click.prevent="updateSelfie()"
            >
              <v-icon>mdi-image</v-icon>
              <span class="grey--text">
                <span class="d-none d-sm-inline">Click to browse</span>
                <span class="d-inline d-sm-none">Tap to browse</span>
                <br />
                Selfie
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog
      v-model="validIDDialog"
      :persistent="updateValidIDLoading"
      max-width="400"
    >
      <v-card class="bs-shadow-none">
        <div class="pa-4 pa-sm-6">
          <div class="font-weight-bold mb-4 mb-sm-6">
            {{ appData.account_details.valid_id ? "Change" : "Submit" }} Valid
            ID
          </div>
          <v-form
            ref="formValidID"
            v-model="formValidID"
            @submit.prevent="doUpdateTheValidID"
          >
            <v-file-input
              ref="valid_id"
              v-model="valid_id"
              :rules="[rules.required]"
              accept=".png,.jpg"
              prepend-icon=""
              append-icon="mdi-image"
              show-size
              @change="validIDOnFileChange"
              @click:clear="validIDClearFile"
              label="Valid ID"
              placeholder="Browse"
              dense
              outlined
              hide-details="auto"
            ></v-file-input>
            <div class="rounded-lg overflow-hidden mt-4" v-if="valid_id_url">
              <v-img :src="valid_id_url">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>
            <div class="mt-4 mt-sm-6 d-flex justify-end bs-gap-2">
              <v-btn
                text
                @click="
                  validIDDialog = false;
                  validIDClearFile();
                "
              >
                Cancel
              </v-btn>
              <v-btn
                :disabled="!formValidID"
                type="submit"
                color="primary"
                depressed
                :loading="updateValidIDLoading"
              >
                Update
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="selfieDialog"
      :persistent="updateSelfieLoading"
      max-width="400"
    >
      <v-card class="bs-shadow-none">
        <div class="pa-4 pa-sm-6">
          <div class="font-weight-bold mb-4 mb-sm-6">
            {{ appData.account_details.selfie ? "Change" : "Submit" }} Selfie
          </div>
          <v-form
            ref="formSelfie"
            v-model="formSelfie"
            @submit.prevent="doUpdateTheSelfie"
          >
            <v-file-input
              ref="selfie"
              v-model="selfie"
              :rules="[rules.required]"
              accept=".png,.jpg"
              prepend-icon=""
              append-icon="mdi-camera"
              show-size
              @change="selfieOnFileChange"
              @click:clear="selfieClearFile"
              label="Selfie"
              placeholder="Browse"
              dense
              outlined
              hide-details="auto"
            ></v-file-input>
            <div class="rounded-lg overflow-hidden mt-4" v-if="selfie_url">
              <v-img :src="selfie_url">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>
            <div class="mt-4 mt-sm-6 d-flex justify-end bs-gap-2">
              <v-btn
                text
                @click="
                  selfieDialog = false;
                  selfieClearFile();
                "
              >
                Cancel
              </v-btn>
              <v-btn
                :disabled="!formSelfie"
                type="submit"
                color="primary"
                depressed
                :loading="updateSelfieLoading"
              >
                Update
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import formatDate from '@/helpers/formatDate'
import { mapState, mapActions } from "vuex";
import formRules from "@/helpers/formRules";
import Compressor from "compressorjs";

export default {
  data: () => ({
    rules: formRules,
    datetimeValidID: 0,
    validIDDialog: false,
    formValidID: false,
    valid_id: null,
    valid_id_url: null,
    datetimeSelfie: 0,
    selfieDialog: false,
    formSelfie: false,
    selfie: null,
    selfie_url: null,
  }),
  computed: {
    ...mapState(["appData", "accountStatus"]),
    ...mapState("account", [
      "updateValidIDHasError",
      "updateValidIDLoading",
      "updateSelfieHasError",
      "updateSelfieLoading",
    ]),
  },
  methods: {
    ...mapActions("account", ["doUpdateValidID", "doUpdateSelfie"]),
    updateValidID() {
      this.validIDDialog = true;
    },
    validIDOnFileChange(e) {
      if (e) {
        this.valid_id_url = URL.createObjectURL(e);
      }
    },
    validIDClearFile() {
      this.valid_id = null;
      this.valid_id_url = null;
    },
    async compressImage(image) {
      return new Promise((resolve) => {
        new Compressor(image, {
          quality: 1,
          width: 800,
          success(result) {
            const compressedImage = new File([result], result.name, {
              type: result.type,
            });
            resolve(compressedImage);
          },
          error(err) {
            console.error("Image compression error:", err);
            resolve(null); // Return null if compression fails
          },
        });
      });
    },
    async doUpdateTheValidID() {
      if (!this.updateValidIDLoading) {
        const formData = new FormData();

        // formData.append("valid_id", this.valid_id);
        const validIdImage = await this.compressImage(this.valid_id);
        if (validIdImage) {
          formData.append("valid_id", validIdImage);
        }

        const res = await this.doUpdateValidID(formData);

        if (!this.updateValidIDHasError) {
          this.appData.account_details.valid_id = res.valid_id;

          this.validIDDialog = false;
          this.validIDClearFile();
          this.$refs.formValidID.reset();

          this.datetimeValidID = this.datetimeValidID + 1; // formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 'format', 'yyyyMMddhhmmss')
        }
      }
    },
    updateSelfie() {
      this.selfieDialog = true;
    },
    selfieOnFileChange(e) {
      if (e) {
        this.selfie_url = URL.createObjectURL(e);
      }
    },
    selfieClearFile() {
      this.selfie = null;
      this.selfie_url = null;
    },
    async doUpdateTheSelfie() {
      if (!this.updateSelfieLoading) {
        const formData = new FormData();

        // formData.append("selfie", this.selfie);
        const selfieImage = await this.compressImage(this.selfie);
        if (selfieImage) {
          formData.append("selfie", selfieImage);
        }

        const res = await this.doUpdateSelfie(formData);

        if (!this.updateSelfieHasError) {
          this.appData.account_details.selfie = res.selfie;

          this.selfieDialog = false;
          this.selfieClearFile();
          this.$refs.formSelfie.reset();

          this.datetimeSelfie = this.datetimeSelfie + 1; // formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 'format', 'yyyyMMddhhmmss')
        }
      }
    },
  },
};
</script>

<style scoped>
.pictures {
  width: 100%;
  height: 200px;
  position: relative;
}

@media (max-width: 768px) {
  .pictures {
    height: 150px;
  }
}
.pic-name-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.75) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
</style>
